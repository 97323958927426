import React, { useState, useRef, useEffect } from "react";
import "./RoomCover.css";
import * as MuiIcons from "@mui/icons-material";

function RoomCover({ roomName, iconName }) {
	const IconComponent = MuiIcons[iconName];

	return (
		<div className="RoomCover">
			<div className="RoomCover-icon">
				{IconComponent ? <IconComponent /> : null}
			</div>
			<div className="RoomCover-roomName">{roomName}</div>
		</div>
	);
}

export default RoomCover;
