import React, { createContext, useState, useEffect, useContext } from "react";

// Create a context for app data
const AppDataContext = createContext(null);

export const AppDataProvider = ({ children }) => {
	const [ICONS, setICONS] = useState([]);
	const [PHRASES, setPHRASES] = useState({});

	// Fetch data from /frontend-data
	useEffect(() => {
		console.log("hey");
		const fetchAppData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/frontend-data`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					},
				);
				console.log("hmm");

				const data = await response.json();
				console.log(data);
				setICONS(data.ICONS || []);
				setPHRASES(data.PHRASES || {});
			} catch (error) {
				console.error("Error fetching app data:", error);
			}
		};

		fetchAppData();
	}, []);

	return (
		<AppDataContext.Provider value={{ ICONS, PHRASES }}>
			{children}
		</AppDataContext.Provider>
	);
};

// Hook to use AppData context
export const useAppData = () => useContext(AppDataContext);
