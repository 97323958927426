import React, { useState, useEffect } from "react";
import "./ElementPicker.css";
import ElementConfiguration from "../ElementConfiguration/ElementConfiguration";
import { ELEMENT_CONFIGURATIONS } from "../ElementConfiguration/configElements";
import Dialog from "../../Dialog/Dialog";

function ElementPicker({ onSelectElement, onClose }) {
	const [selectedElement, setSelectedElement] = useState(null);

	const handleElementSelection = (element) => {
		setSelectedElement(element);

		// If element is not in ELEMENT_CONFIGURATIONS, it doesn't need a config screen
		if (!ELEMENT_CONFIGURATIONS[element]) {
			onSelectElement(element, null);
		}
	};

	const handleConfigurationComplete = (config) => {
		onSelectElement(selectedElement, config);
	};

	return (
		<Dialog
			onClose={onClose}
			closable={true}
			style={{ backgroundColor: "#333333" }}
		>
			{selectedElement && ELEMENT_CONFIGURATIONS[selectedElement] ? (
				<ElementConfiguration
					element={selectedElement}
					onConfigurationComplete={handleConfigurationComplete}
				/>
			) : selectedElement ? (
				<h3>Selected {selectedElement}</h3>
			) : (
				<>
					<h3>Select Element Type</h3>
					<button onClick={() => handleElementSelection("Text")}>
						Text
					</button>
					<button onClick={() => handleElementSelection("Room")}>
						Room
					</button>
					<button onClick={() => handleElementSelection("TextInput")}>
						Text Field
					</button>
					<button onClick={() => handleElementSelection("DataTable")}>
						Table
					</button>
					<button
						onClick={() => handleElementSelection("FileStorage")}
					>
						File Storage
					</button>
					<button
						onClick={() => handleElementSelection("ActionButton")}
					>
						Action Button
					</button>
					<button onClick={() => handleElementSelection("Room3d")}>
						Room3d
					</button>
					<button onClick={() => handleElementSelection("Calendar")}>
						Calendar
					</button>
					<button onClick={() => handleElementSelection("Note")}>
						Note Editor
					</button>
					<button
						onClick={() => handleElementSelection("ActionDialog")}
					>
						Action
					</button>
				</>
			)}
		</Dialog>
	);
}

export default ElementPicker;
