import React, { useRef, useState } from "react";
import "./SamanthaInput.css";

function SamanthaInput({ functionToExecute }) {
	const textContent = useRef(null);
	const [userPrompt, setUserPrompt] = useState("");

	const handleKeyDown = (e) => {
		if (e.key === "Enter" && !e.shiftKey && !!userPrompt.trim()) {
			e.preventDefault();
			functionToExecute(userPrompt);

			// Clear existing text content
			textContent.current.innerText = "";
		}
	};

	return (
		<div className="SamanthaInput">
			<div
				className="SamanthaInput-text"
				contentEditable="true"
				placeholder="Command me..."
				onKeyDown={handleKeyDown}
				ref={textContent}
				onInput={(e) => setUserPrompt(e.target.innerText)}
			/>
		</div>
	);
}

export default SamanthaInput;
