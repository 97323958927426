import React, { createContext, useContext, useState, useCallback } from "react";

const SamanthaContext = createContext();

export const SamanthaProvider = ({ children }) => {
	const [isVisible, setIsVisible] = useState(false);
	const [text, setText] = useState([]);
	const [stayOpen, setStayOpen] = useState(false);

	const showSamantha = useCallback((newText, options = {}) => {
		setText(newText);
		setStayOpen(options.stayOpen || false);
		setIsVisible(true);
	}, []);

	const hideSamantha = useCallback(() => {
		setIsVisible(false);
		setText([]);
	}, []);

	return (
		<SamanthaContext.Provider
			value={{
				isVisible,
				text,
				stayOpen,
				showSamantha,
				hideSamantha,
			}}
		>
			{children}
		</SamanthaContext.Provider>
	);
};

export const useSamantha = () => useContext(SamanthaContext);
