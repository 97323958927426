// PickerStep.js
import React from "react";
import { Box, Typography } from "@mui/material";
import DialogPicker from "../../../../Dialog/DialogPicker/DialogPicker";
import DialogPickerItem from "../../../../Dialog/DialogPicker/DialogPickerItem/DialogPickerItem";

export default function PickerStep({ onNext, onClose }) {
	const handleOptionClick = (option) => {
		// If user chooses "new", next step is "newCalendarNameStep".
		// If user chooses "existing", next step is "chooseExistingCalendarStep".
		if (option === "new") {
			onNext("calendarInfoStep", { calendarOption: "new" });
		} else {
			onNext("chooseExistingCalendarStep", {
				calendarOption: "existing",
			});
		}
	};

	return (
		<DialogPicker style={{ width: "600px" }}>
			<DialogPickerItem id="new" clickEvent={handleOptionClick}>
				New Calendar
			</DialogPickerItem>
			<DialogPickerItem id="existing" clickEvent={handleOptionClick}>
				Existing Calendar
			</DialogPickerItem>
		</DialogPicker>
	);
}
