import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import "./ActionDialog.css";
import { useAccessToken } from "../../../../../services/AccessTokenProvider";
import * as MuiIcons from "@mui/icons-material";

function ActionDialog({ actionName }) {
	const { accessToken } = useAccessToken();
	const [fields, setFields] = useState([]); // Fields to render
	const [fieldValues, setFieldValues] = useState({}); // State to store field values
	const [loading, setLoading] = useState(true);
	const [actionDisplayName, setActionDisplayName] = useState("");
	const [actionIcon, setActionIcon] = useState(null);

	const commonTextFieldStyles = {
		input: {
			color: "#9e9e9e",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#9e9e9e",
			},
			"&:hover fieldset": {
				borderColor: "rgb(233,233,233)",
			},
		},
		"& .MuiInputLabel-root": {
			color: "#9e9e9e",
		},
	};

	// Fetch metadata for the action when actionName changes
	useEffect(() => {
		const fetchFields = async () => {
			try {
				setLoading(true);
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/get-action-fields?actionName=${actionName}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);
				const data = await response.json();

				if (response.ok) {
					setActionDisplayName(data.display_name);
					setActionIcon(data.icon);
					setFields(data.fields || []);
					initializeFieldValues(data.fields || []);
				} else {
					console.error("Failed to fetch action fields:", data.error);
				}
			} catch (err) {
				console.error("Error fetching action fields:", err);
			} finally {
				setLoading(false);
			}
		};

		if (actionName) {
			fetchFields();
		}
	}, [actionName, accessToken]);

	const initializeFieldValues = (fields) => {
		const initialValues = {};
		fields.forEach((field) => {
			if (field.type === "array") {
				initialValues[field.name] = [{}]; // Start with one empty group
			} else {
				initialValues[field.name] = ""; // Default empty value
			}
		});
		setFieldValues(initialValues);
	};

	const handleFieldChange = (fieldName, value, arrayIndex = null) => {
		setFieldValues((prev) => {
			const updatedValues = { ...prev };
			if (arrayIndex !== null) {
				// Update specific array index for array fields
				updatedValues[fieldName][arrayIndex] = value;
			} else {
				// Update value for non-array fields
				updatedValues[fieldName] = value;
			}
			return updatedValues;
		});
	};

	const validateFields = () => {
		for (const field of fields) {
			if (field.required && !fieldValues[field.name]) {
				return false;
			}
			if (
				field.type === "array" &&
				fieldValues[field.name]?.some((group) => {
					return field.subfields.some(
						(subfield) =>
							subfield.required && !group[subfield.name],
					);
				})
			) {
				return false;
			}
		}
		return true;
	};

	const handleSave = async () => {
		if (!validateFields()) {
			alert("Please fill out all required fields.");
			return;
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/execute-action`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify({
						actionName: actionName,
						fieldValues: fieldValues,
					}),
				},
			);

			const result = await response.json();
			if (response.ok) {
				alert("Action executed successfully!");
				// Reset all field values after success
				initializeFieldValues(fields);
			} else {
				alert(
					`Failed to execute action: ${result.error || "Unknown error"}`,
				);
			}
		} catch (error) {
			console.error("Error executing action:", error);
			alert("Error executing action. Check console for details.");
		}
	};

	const addArrayGroup = (fieldName) => {
		setFieldValues((prev) => ({
			...prev,
			[fieldName]: [...prev[fieldName], {}],
		}));
	};

	const removeLastArrayGroup = (fieldName) => {
		setFieldValues((prev) => ({
			...prev,
			[fieldName]: prev[fieldName].slice(0, -1),
		}));
	};

	const renderField = (field, arrayIndex = null, fieldGroupName = null) => {
		const fieldKey =
			arrayIndex !== null ? `${field.name}-${arrayIndex}` : field.name;
		const value =
			arrayIndex !== null
				? fieldValues[fieldGroupName][arrayIndex][field.name] || ""
				: fieldValues[field.name] || "";

		const isArraySubfield = arrayIndex !== null;

		switch (field.type) {
			case "textField":
				return (
					<TextField
						key={fieldKey}
						label={field.display_name}
						value={value}
						onChange={(e) => {
							const newValue = e.target.value;
							if (isArraySubfield) {
								handleFieldChange(
									fieldGroupName,
									{
										...fieldValues[fieldGroupName][
											arrayIndex
										],
										[field.name]: newValue,
									},
									arrayIndex,
								);
							} else {
								handleFieldChange(field.name, newValue);
							}
						}}
						fullWidth
						margin="dense"
						size="small"
						sx={commonTextFieldStyles}
					/>
				);
			case "numberField":
				return (
					<TextField
						key={fieldKey}
						label={field.display_name}
						type="number"
						value={value}
						onChange={(e) => {
							const newValue = e.target.value;
							if (isArraySubfield) {
								handleFieldChange(
									fieldGroupName,
									{
										...fieldValues[fieldGroupName][
											arrayIndex
										],
										[field.name]: newValue,
									},
									arrayIndex,
								);
							} else {
								handleFieldChange(field.name, newValue);
							}
						}}
						fullWidth
						margin="dense"
						size="small"
						sx={commonTextFieldStyles}
					/>
				);
			case "checkbox":
				return (
					<FormControlLabel
						key={fieldKey}
						control={
							<Checkbox
								checked={!!value}
								onChange={(e) => {
									const newValue = e.target.checked;
									if (isArraySubfield) {
										handleFieldChange(
											fieldGroupName,
											{
												...fieldValues[fieldGroupName][
													arrayIndex
												],
												[field.name]: newValue,
											},
											arrayIndex,
										);
									} else {
										handleFieldChange(field.name, newValue);
									}
								}}
							/>
						}
						label={field.display_name}
						style={{ color: "#9e9e9e" }}
					/>
				);
			case "select":
				return (
					<FormControl
						key={fieldKey}
						fullWidth
						margin="dense"
						size="small"
						sx={{
							"& .MuiInputLabel-root": {
								color: "#9e9e9e",
							},
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									borderColor: "#9e9e9e",
								},
								"&:hover fieldset": {
									borderColor: "rgb(233,233,233)",
								},
								"& .MuiSelect-select": {
									color: "#9e9e9e",
								},
							},
							"& .MuiSvgIcon-root": {
								color: "#9e9e9e",
							},
						}}
					>
						<InputLabel>{field.display_name}</InputLabel>
						<Select
							value={value || ""} // Ensure value is never undefined
							onChange={(e) => {
								const newValue = e.target.value;
								if (isArraySubfield) {
									handleFieldChange(
										fieldGroupName,
										{
											...fieldValues[fieldGroupName][
												arrayIndex
											],
											[field.name]: newValue,
										},
										arrayIndex,
									);
								} else {
									handleFieldChange(field.name, newValue);
								}
							}}
							label={field.display_name}
						>
							{(field.options || []).map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				);
			case "datePicker":
				return (
					<DatePicker
						key={fieldKey}
						label={field.display_name}
						value={value || null}
						onChange={(newValue) => {
							if (isArraySubfield) {
								handleFieldChange(
									fieldGroupName,
									{
										...fieldValues[fieldGroupName][
											arrayIndex
										],
										[field.name]: newValue,
									},
									arrayIndex,
								);
							} else {
								handleFieldChange(field.name, newValue);
							}
						}}
						slotProps={{
							textField: {
								fullWidth: true,
								margin: "dense",
								size: "small",
								sx: commonTextFieldStyles,
							},
						}}
					/>
				);
			case "dateTimePicker":
				return (
					<DateTimePicker
						key={fieldKey}
						label={field.display_name}
						value={value || null}
						onChange={(newValue) => {
							if (isArraySubfield) {
								handleFieldChange(
									fieldGroupName,
									{
										...fieldValues[fieldGroupName][
											arrayIndex
										],
										[field.name]: newValue,
									},
									arrayIndex,
								);
							} else {
								handleFieldChange(field.name, newValue);
							}
						}}
						slotProps={{
							textField: {
								fullWidth: true,
								margin: "dense",
								size: "small",
								sx: commonTextFieldStyles,
							},
						}}
					/>
				);
			case "array":
				return (
					<div
						key={field.name}
						className="array-container"
						style={{
							width: "90%",
							padding: "10px",
							marginTop: "15px",
							borderRadius: "5px",
						}}
					>
						<div
							style={{
								textAlign: "center",
								fontWeight: "bold",
								marginBottom: "10px",
								color: "#9e9e9e",
							}}
						>
							{field.display_name}
						</div>
						{fieldValues[field.name]?.map((group, index) => (
							<div
								key={`${field.name}-${index}`}
								style={{
									marginTop: index !== 0 ? "20px" : "0px", // Add margin top for non-first groups
								}}
							>
								{field.subfields.map((subfield) =>
									renderField(subfield, index, field.name),
								)}
							</div>
						))}
						<div style={{ textAlign: "center", marginTop: "10px" }}>
							<IconButton
								color="primary"
								onClick={() => addArrayGroup(field.name)}
							>
								<AddCircleIcon />
							</IconButton>
							{fieldValues[field.name]?.length > 1 && (
								<IconButton
									color="secondary"
									onClick={() =>
										removeLastArrayGroup(field.name)
									}
								>
									<RemoveCircleIcon />
								</IconButton>
							)}
						</div>
					</div>
				);

			default:
				return null;
		}
	};

	const IconComponent =
		actionIcon && MuiIcons[actionIcon] ? MuiIcons[actionIcon] : null;

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<div className="ActionDialog">
				<div className="ActionDialog-header">
					{IconComponent && (
						<IconComponent
							style={{
								fontSize: "30px",
								color: "#9e9e9e",
								margin: "10px auto",
								display: "block",
							}}
						/>
					)}
				</div>
				<div className="ActionDialog-content">
					{loading ? (
						<p>Loading...</p>
					) : (
						fields.map((field) => renderField(field))
					)}
					<Button
						variant="contained"
						color="primary"
						onClick={handleSave}
						fullWidth
						sx={{ marginTop: "20px" }}
						size="large"
					>
						{actionDisplayName}
					</Button>
				</div>
			</div>
		</LocalizationProvider>
	);
}

export default ActionDialog;
