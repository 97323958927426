import "./Welcome.css";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import bgImage from "../../../resources/colour_gradient_blurred.png";
import { useAccessToken } from "../../../services/AccessTokenProvider";
import { useUserData } from "../../../services/UserDataProvider";

function Welcome() {
	const { accessToken } = useAccessToken();
	const { reloadUserData } = useUserData();

	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [palaceName, setPalaceName] = useState("");

	const handleGetStartedClick = async () => {
		const data = {
			firstname: firstname,
			lastname: lastname,
			palace_name: palaceName,
		};

		console.log(accessToken);
		await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/welcome`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(data),
		});

		// context fetches the updated user data again, this reloads the page and thus closes this component
		reloadUserData();
	};

	return (
		<div className="Welcome">
			<div className="Welcome-box">
				<div>First time?</div>
				<TextField
					id="outlined-basic"
					label="Firstname"
					variant="outlined"
					value={firstname}
					onChange={(e) => setFirstname(e.target.value)}
				/>
				<TextField
					id="outlined-basic"
					label="Lastname"
					variant="outlined"
					value={lastname}
					onChange={(e) => setLastname(e.target.value)}
				/>

				<TextField
					id="outlined-basic"
					label="Palace Name"
					variant="outlined"
					value={palaceName}
					onChange={(e) => setPalaceName(e.target.value)}
				/>

				<Button
					variant="contained"
					style={{
						backgroundImage: `url(${bgImage})`,
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
						color: "#fff",
					}}
					onClick={handleGetStartedClick}
				>
					Get started
				</Button>
			</div>
		</div>
	);
}

export default Welcome;
