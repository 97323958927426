import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import "./RoomConfig.css";
import IconPicker from "../../../../../utils/customReusableComponents/IconPicker/IconPicker";
import { useDialogContext } from "../../../../../../services/DialogProvider";
import { useAppData } from "../../../../../../services/AppDataProvider";
import * as MuiIcons from "@mui/icons-material";

function RoomConfig({ registerApplyConfig }) {
	const { pushDialog, popDialog } = useDialogContext();
	const { ICONS } = useAppData();

	const [roomName, setRoomName] = useState("");
	const [selectedIcon, setSelectedIcon] = useState(ICONS[0]); // Default icon

	const validateFields = () => {
		return roomName !== "";
	};

	registerApplyConfig(() => {
		if (validateFields()) {
			let config = {
				roomName: roomName,
				icon: selectedIcon,
				elements: [],
			};
			return config;
		}
	});

	const handleIconSelect = (iconName) => {
		popDialog();
		setSelectedIcon(iconName);
	};

	const handleIconClick = () => {
		console.log(ICONS);
		pushDialog(
			<IconPicker onIconSelect={handleIconSelect} icons={ICONS} />,
		);
	};

	const SelectedIconComponent = MuiIcons[selectedIcon];

	return (
		<div className="RoomConfig">
			<h2>Room Settings</h2>
			<div className="icon-preview">
				<IconButton onClick={handleIconClick}>
					{SelectedIconComponent && <SelectedIconComponent />}
				</IconButton>
			</div>
			<TextField
				id="outlined-basic"
				label="Room Name"
				variant="outlined"
				value={roomName}
				onChange={(e) => setRoomName(e.target.value)}
				sx={{ width: "300px" }}
			/>
		</div>
	);
}

export default RoomConfig;
