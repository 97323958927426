import React from "react";
import IconButton from "@mui/material/IconButton";
import * as MuiIcons from "@mui/icons-material";
import "./IconPicker.css";

function IconPicker({ onIconSelect, icons }) {
	return (
		<div className="IconPicker">
			<div className="IconPicker-grid">
				{icons.map((iconName, index) => {
					const IconComponent = MuiIcons[iconName]; // Dynamically resolve icon

					return IconComponent ? (
						<IconButton
							key={index}
							onClick={() => onIconSelect(iconName)}
						>
							<IconComponent />
						</IconButton>
					) : null;
				})}
			</div>
		</div>
	);
}

export default IconPicker;
