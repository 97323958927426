import React from "react";
import { coverConfig } from "./Cover/coverConfig";
import Cover from "./Cover/Cover";
import "./ResizableMovableComponent.css";
import Paper from "@mui/material/Paper";
import { usePalaceConfigContext } from "../../../../services/PalaceConfigProvider";
import { renderElement } from "../../../utils/utils";

function ResizableMovableComponent({
	element,
	isEditMode,
	handleElementPress,
	handleResizePress,
	onClick,
	level,
}) {
	const { gridFieldSize } = usePalaceConfigContext();

	const isRoom = element.type === "Room";

	const { minHeight, minWidth } =
		coverConfig[element.type] || coverConfig.default;

	const hasContent = element.height >= minHeight && element.width >= minWidth;

	return (
		<Paper
			className="ResizableMovableComponent"
			elevation={8}
			style={{
				left: `${element.x * gridFieldSize}px`,
				top: `${element.y * gridFieldSize}px`,
				width: `${element.width * gridFieldSize}px`,
				height: `${element.height * gridFieldSize}px`,
				position: "absolute",
				borderRadius:
					element.width === 1 || element.height === 1
						? "5px"
						: "16px",
				overflow: isRoom ? "visible" : "hidden",
				backgroundColor: isRoom ? "transparent" : undefined,
				boxShadow: isRoom ? "none" : undefined,
				padding: "0px",
			}}
			onMouseDown={isEditMode ? handleElementPress : null}
			onTouchStart={isEditMode ? handleElementPress : null}
			onClick={!isEditMode ? onClick : null}
		>
			<div
				style={{
					width: "100%",
					height: "100%",
					pointerEvents: isEditMode || level !== 0 ? "none" : "auto",
					borderRadius:
						element.width === 1 || element.height === 1
							? "5px"
							: "16px",
					overflow: "visible",
					boxSizing: "border-box",
				}}
			>
				{hasContent ? (
					renderElement(element, level)
				) : (
					<Cover
						element={element}
						isEditMode={isEditMode}
						width={element.width}
						height={element.height}
					/>
				)}
			</div>
			{isEditMode && (
				<div
					className="resize-handle"
					onMouseDown={handleResizePress}
					onTouchStart={handleResizePress}
				/>
			)}
		</Paper>
	);
}

export default ResizableMovableComponent;
