import React, { useState, useRef } from "react";
import "./CommandLine.css";
import { useAccessToken } from "../../../../../services/AccessTokenProvider";
import SamanthaInput from "../../../../utils/customReusableComponents/SamanthaInput/SamanthaInput";

function CommandLine(props) {
	const { accessToken } = useAccessToken();

	async function handleSendUserPrompt(userPrompt) {
		const payload = {
			user_prompt: userPrompt,
		};

		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/execute-userprompt`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify(payload),
				},
			);

			const data = await response.json();

			props.showResponse(data.response);
		} catch (error) {
			console.error("Error sending user prompt:", error);
		}
	}

	return <SamanthaInput functionToExecute={handleSendUserPrompt} />;
}

export default CommandLine;
