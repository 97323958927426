import React from "react";
import "./AI.css";
import Room3d from "../../views/Palace/Room/Room3d/Room3d";
import AIDialog from "./AIDialog/AIDialog";
import { useDialogContext } from "../../../services/DialogProvider";

function AI() {
	const { pushDialog, clearDialogStack, dialogStack } = useDialogContext();

	const handleDialogToggle = () => {
		// Check if AIDialog is already in the dialog stack
		const isAIDialogInStack = dialogStack.some(
			(dialog) => dialog.component?.type === AIDialog,
		);

		if (isAIDialogInStack) {
			// If AIDialog exists, clear the dialog stack
			clearDialogStack();
		} else {
			// Push a new AIDialog to the stack
			pushDialog(<AIDialog />, true); // Pass component and closable flag
		}
	};

	return (
		<div className="AI">
			<div
				style={{ height: "38px", aspectRatio: "1/1" }}
				onClick={handleDialogToggle}
			>
				<Room3d
					width={1}
					height={1}
					gridFieldSize={38}
					animation={"weird"}
					type={"skeleton"}
				/>
			</div>
		</div>
	);
}

export default AI;
