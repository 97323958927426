import React, { useEffect, useState } from "react";
import { useSamantha } from "../../../../../../../services/SamanthaProvider";
import CommandLine from "../../../../../../Page/AI/AIDialog/CommandLine/CommandLine";
import SamanthaInput from "../../../../../../utils/customReusableComponents/SamanthaInput/SamanthaInput";

export default function CalendarInfoStep({ onNext }) {
	const { showSamantha, hideSamantha } = useSamantha();

	useEffect(() => {
		showSamantha(["What is the calendar used for?"], {
			stayOpen: true,
		});
		return () => {
			hideSamantha();
		};
	}, []);

	const handleSubmit = (description) => {
		if (!description.trim()) return;
		// Move to next step with name included
		onNext(null, { description: description });
	};

	return <SamanthaInput functionToExecute={handleSubmit} />;
}
