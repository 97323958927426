import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Auth0Provider } from "@auth0/auth0-react";
import { AccessTokenProvider } from "./services/AccessTokenProvider";
import { UserDataProvider } from "./services/UserDataProvider";
import AccessTokenLoader from "./Authentication/AccessTokenLoader";
import materialUiTheme from "./services/materialUiTheme";
import { ThemeContextProvider } from "./services/ThemeProvider";
import { SamanthaProvider } from "./services/SamanthaProvider";
import { AccessoryInputProvider } from "./services/AccessoryInputProvider";
import { AppDataProvider } from "./services/AppDataProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

root.render(
	<AppDataProvider>
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			authorizationParams={{
				redirect_uri: redirectUri,
				audience: `https://thought-palace`,
				scope: "openid offline_access read:current_user",
			}}
			useRefreshTokens={true} // Enable refresh tokens
			cacheLocation="localstorage" // Ensure tokens are stored in localStorage for better compatibility
		>
			<AccessTokenProvider>
				<AccessTokenLoader>
					<UserDataProvider>
						<AccessoryInputProvider>
							<ThemeContextProvider>
								<ThemeProvider theme={materialUiTheme}>
									<SamanthaProvider>
										<App />
									</SamanthaProvider>
								</ThemeProvider>
							</ThemeContextProvider>
						</AccessoryInputProvider>
					</UserDataProvider>
				</AccessTokenLoader>
			</AccessTokenProvider>
		</Auth0Provider>
	</AppDataProvider>,
);
