import React from "react";
import {
	Drawer,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import StarIcon from "@mui/icons-material/Star";
import HelpIcon from "@mui/icons-material/Help";
import AppsIcon from "@mui/icons-material/Apps";
import CastleIcon from "@mui/icons-material/Castle";
import UserMenu from "./UserMenu/UserMenu";
import AI from "../AI/AI";
import "./Sidebar.css";
import { Dashboard } from "@mui/icons-material";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(0, 1),
	justifyContent: "flex-end",
}));

function Sidebar() {
	return (
		<div>
			<Drawer
				variant="persistent"
				open={true}
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
						backgroundColor: "#333333", // Dark grey background
						color: "white", // White text color
						display: "flex",
						justifyContent: "space-between",
					},
				}}
			>
				<div className="Sidebar-topItems">
					<AI />
					<div className="Sidebar-list">
						<List>
							<Link to="/thought-palace" className="Sidebar-link">
								<ListItemButton sx={{ color: "white" }}>
									<ListItemIcon sx={{ color: "white" }}>
										<CastleIcon />
									</ListItemIcon>
									<ListItemText primary="Thought Palace" />
								</ListItemButton>
							</Link>
							<Link
								to="/thought-palace/abilities"
								className="Sidebar-link"
							>
								<ListItemButton sx={{ color: "white" }}>
									<ListItemIcon sx={{ color: "white" }}>
										<StarIcon />
									</ListItemIcon>
									<ListItemText primary="Abilities" />
								</ListItemButton>
							</Link>
							<ListItemButton sx={{ color: "white" }}>
								<ListItemIcon sx={{ color: "white" }}>
									<Dashboard />
								</ListItemIcon>
								<ListItemText primary="Dashboard" />
							</ListItemButton>
							<ListItemButton sx={{ color: "white" }}>
								<ListItemIcon sx={{ color: "white" }}>
									<SettingsIcon />
								</ListItemIcon>
								<ListItemText primary="Settings" />
							</ListItemButton>
							<ListItemButton sx={{ color: "white" }}>
								<ListItemIcon sx={{ color: "white" }}>
									<HelpIcon />
								</ListItemIcon>
								<ListItemText primary="Help" />
							</ListItemButton>
						</List>
					</div>
				</div>
				<div className="UserItem-container">
					<UserMenu />
				</div>
			</Drawer>
		</div>
	);
}

export default Sidebar;
