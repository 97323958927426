import { AccessTokenStaticProvider } from "../../../../../services/AccessTokenProvider";

export const handleElementConfig = async (type, config) => {
	const accessToken = AccessTokenStaticProvider.getAccessToken();

	switch (type) {
		case "DataTable":
			if (config.assignTableType === "create_new") {
				const tableInfo = config["tableInfo"];
				const payload = {
					name: tableInfo["table_name"],
					description: tableInfo["table_description"],
					columns: tableInfo["columns"],
				};
				try {
					const response = await fetch(
						`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/create-table`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${accessToken}`,
							},
							body: JSON.stringify(payload),
						},
					);

					const data = await response.json();

					return {
						tableName: data.tableName,
					};
				} catch (error) {
					console.error("Error sending user prompt:", error);
				}
			} else if (config.assignTableType === "existing") {
				return {
					tableName: config.tableName,
				};
			}
			break;
		case "Calendar":
			if (config.calendarOption !== "new") {
				console.log(config.selectedCalendarId);
				return {
					calendarId: config.selectedCalendarId,
				};
			}

			const description = config["description"];

			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/create-calendar`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
						body: JSON.stringify({ description: description }),
					},
				);

				const data = await response.json();

				return {
					calendarId: data.calendarId,
				};
			} catch (error) {
				console.error("Error sending user prompt:", error);
			}

			break;

		case "Note":
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/create-note`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
						body: JSON.stringify({}),
					},
				);

				const data = await response.json();

				return {
					noteId: data.noteId,
				};
			} catch (error) {
				console.error("Error sending user prompt:", error);
			}

			break;

		// Add more cases for other types of elements as needed
		default:
			return config;
	}
};
