// MultiStageDialog.js
import React, { useState, useEffect, useRef } from "react";
import Dialog from "../Dialog";
export default function MultiStageDialog({
	steps,
	initialStep,
	onClose: onDialogClose,
}) {
	const [currentStep, setCurrentStep] = useState(initialStep);
	const dialogDataRef = useRef({});

	const handleNext = (nextStepKey, data) => {
		// Merge new data into globalData if provided
		if (data && typeof data === "object") {
			Object.assign(dialogDataRef.current, data);
		}

		if (nextStepKey) {
			// Move to next step
			setCurrentStep(nextStepKey);
		} else {
			// If no next step is given, close the dialog
			handleClose();
		}
	};

	const handleClose = () => {
		// Close the entire dialog flow
		onDialogClose && onDialogClose(dialogDataRef.current);
	};

	const CurrentStepComponent = steps[currentStep];
	if (!CurrentStepComponent) return null;

	return (
		<>
			<CurrentStepComponent onNext={handleNext} onClose={handleClose} />
		</>
	);
}
