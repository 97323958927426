import React, { useState, useRef } from "react";
import "./Room.css";
import ResizableMovableComponent from "./ResizableMovableComponent";
import ElementPicker from "./ElementPicker/ElementPicker";
import { handleElementConfig } from "./ElementConfiguration/configHandlers";
import { useThemeContext } from "../../../../services/ThemeProvider";
import Room3d from "./Room3d/Room3d";
import { usePalaceConfigContext } from "../../../../services/PalaceConfigProvider";
import { getEventCoordinates } from "../../../utils/utils";
import { useDialogContext } from "../../../../services/DialogProvider";
import * as MuiIcons from "@mui/icons-material";
import RoomCover from "./RoomCover/RoomCover";

function Room({
	id,
	roomName,
	isEditMode,
	elements,
	level,
	setSelectedRoomId,
	goToParentRoom,
	iconName,
}) {
	const { gridFieldSize, palaceWidth, palaceHeight, updatePalaceData } =
		usePalaceConfigContext();

	const { pushDialog, popDialog } = useDialogContext();

	const { getThemeForComponent } = useThemeContext();
	const { findElementById } = usePalaceConfigContext();
	const currentTheme = getThemeForComponent("room");

	const gridWidth = palaceWidth * gridFieldSize;
	const gridHeight = palaceHeight * gridFieldSize;

	const isRoom3D = false;

	const [startPosition, setStartPosition] = useState(null);
	const [currentPosition, setCurrentPosition] = useState(null);

	const roomElements = elements;

	const IconComponent = MuiIcons[iconName];
	console.log(iconName);

	const isColliding = (element1, element2) => {
		return !(
			element1.x + element1.width <= element2.x ||
			element1.x >= element2.x + element2.width ||
			element1.y + element1.height <= element2.y ||
			element1.y >= element2.y + element2.height
		);
	};

	const willCollide = (id, newX, newY, newWidth, newHeight) => {
		const currentElement = {
			id,
			x: newX,
			y: newY,
			width: newWidth,
			height: newHeight,
		};
		return roomElements.some(
			(element) =>
				element.id !== id && isColliding(currentElement, element),
		);
	};

	const willCollideWithElements = (newX, newY, newWidth, newHeight) => {
		const selectionRect = {
			x: newX,
			y: newY,
			width: newWidth,
			height: newHeight,
		};
		return roomElements.some((element) =>
			isColliding(selectionRect, element),
		);
	};

	const handleElementPress = (e, id) => {
		if (!isEditMode || level !== 0) return;

		e.preventDefault(); // Prevent default touch behavior
		e.stopPropagation();

		const { x: startX, y: startY } = getEventCoordinates(e);

		const selectedElement = roomElements.find(
			(element) => element.id === id,
		);
		const offsetX = selectedElement.x;
		const offsetY = selectedElement.y;

		const handleMove = (e) => {
			const { x: currentX, y: currentY } = getEventCoordinates(e);

			let newX =
				offsetX + Math.round((currentX - startX) / gridFieldSize);
			let newY =
				offsetY + Math.round((currentY - startY) / gridFieldSize);

			newX = Math.max(
				0,
				Math.min(newX, palaceWidth - selectedElement.width),
			);
			newY = Math.max(
				0,
				Math.min(newY, palaceHeight - selectedElement.height),
			);

			if (
				!willCollide(
					id,
					newX,
					newY,
					selectedElement.width,
					selectedElement.height,
				)
			) {
				updatePalaceData((draft) => {
					const element = findElementById(draft, id);
					element.x = newX;
					element.y = newY;
				});
			}
		};

		const handleEnd = () => {
			document.removeEventListener("mousemove", handleMove);
			document.removeEventListener("mouseup", handleEnd);
			document.removeEventListener("touchmove", handleMove);
			document.removeEventListener("touchend", handleEnd);
		};

		document.addEventListener("mousemove", handleMove);
		document.addEventListener("mouseup", handleEnd);
		document.addEventListener("touchmove", handleMove, { passive: false });
		document.addEventListener("touchend", handleEnd);
	};

	const handleResizePress = (e, id) => {
		if (!isEditMode || level !== 0) return;

		e.stopPropagation();
		const selectedElement = roomElements.find(
			(element) => element.id === id,
		);

		const { x: startX, y: startY } = getEventCoordinates(e);

		const startWidth = selectedElement.width;
		const startHeight = selectedElement.height;

		const onMove = (e) => {
			const { x: currentX, y: currentY } = getEventCoordinates(e);
			let newWidth =
				startWidth + Math.round((currentX - startX) / gridFieldSize);
			let newHeight =
				startHeight + Math.round((currentY - startY) / gridFieldSize);

			newWidth = Math.max(1, newWidth);
			newHeight = Math.max(1, newHeight);
			newWidth = Math.min(newWidth, palaceWidth - selectedElement.x);
			newHeight = Math.min(newHeight, palaceHeight - selectedElement.y);

			if (
				!willCollide(
					id,
					selectedElement.x,
					selectedElement.y,
					newWidth,
					newHeight,
				)
			) {
				updatePalaceData((draft) => {
					const element = findElementById(draft, id);
					element.width = newWidth;
					element.height = newHeight;
				});
			}
		};

		const onEnd = () => {
			document.removeEventListener("mousemove", onMove);
			document.removeEventListener("mouseup", onEnd);
			document.removeEventListener("touchmove", onMove);
			document.removeEventListener("touchend", onEnd);
		};

		document.addEventListener("mousemove", onMove);
		document.addEventListener("mouseup", onEnd);
		document.addEventListener("touchmove", onMove, { passive: false });
		document.addEventListener("touchend", onEnd);
	};

	// Handle right-click to go back to the parent room
	const handleRightClick = (e) => {
		e.preventDefault(); // Prevent the default context menu

		if (level !== 0) return;

		goToParentRoom(id);
	};

	const isClickOnEmptySpace = (e) => {
		const roomBounds = e.currentTarget.getBoundingClientRect();
		const clickedX = Math.floor(
			(e.clientX - roomBounds.left) / gridFieldSize,
		);
		const clickedY = Math.floor(
			(e.clientY - roomBounds.top) / gridFieldSize,
		);
		// Check if any element overlaps the clicked position
		return !roomElements.some((element) => {
			const elementXEnd = element.x + element.width - 1;
			const elementYEnd = element.y + element.height - 1;
			return (
				clickedX >= element.x &&
				clickedX <= elementXEnd &&
				clickedY >= element.y &&
				clickedY <= elementYEnd
			);
		});
	};

	const renderSelectionRectangle = () => {
		if (!startPosition || !currentPosition) return null;

		const minX = Math.min(startPosition.x, currentPosition.x);
		const minY = Math.min(startPosition.y, currentPosition.y);
		const width = Math.abs(currentPosition.x - startPosition.x) + 1;
		const height = Math.abs(currentPosition.y - startPosition.y) + 1;

		return (
			<div
				style={{
					position: "absolute",
					left: `${minX * gridFieldSize}px`,
					top: `${minY * gridFieldSize}px`,
					width: `${width * gridFieldSize}px`,
					height: `${height * gridFieldSize}px`,
					backgroundColor: "rgba(44, 62, 80, 0.5)", // Some translucent color
				}}
			/>
		);
	};

	const handleMouseMove = (e) => {
		if (!startPosition) return; // Only proceed if mouse is down

		// Get the bounding rectangle of the grid container
		const roomRect = e.currentTarget.getBoundingClientRect();

		const { x: clientX, y: clientY } = getEventCoordinates(e);

		// Calculate the mouse position relative to the grid container
		let relativeX = clientX - roomRect.left;
		let relativeY = clientY - roomRect.top;

		// Calculate the grid position
		let currentX = Math.floor(relativeX / gridFieldSize);
		let currentY = Math.floor(relativeY / gridFieldSize);

		// bugfix where currentLocation can be greater than palace or smaller than 0
		if (currentX < 0) currentX = 0;
		if (currentY < 0) currentY = 0;
		if (currentX >= palaceWidth) currentX = palaceWidth - 1;
		if (currentY >= palaceHeight) currentY = palaceHeight - 1;

		const minX = Math.min(startPosition.x, currentX);
		const minY = Math.min(startPosition.y, currentY);
		const width = Math.abs(currentX - startPosition.x) + 1;
		const height = Math.abs(currentY - startPosition.y) + 1;

		if (!willCollideWithElements(minX, minY, width, height)) {
			setCurrentPosition({ x: currentX, y: currentY });
		}
	};

	// Handle mouse up (end of rectangle selection)
	const handleMouseUp = () => {
		if (startPosition && currentPosition) {
			const width = Math.abs(currentPosition.x - startPosition.x) + 1;
			const height = Math.abs(currentPosition.y - startPosition.y) + 1;
			const minX = Math.min(startPosition.x, currentPosition.x);
			const minY = Math.min(startPosition.y, currentPosition.y);

			pushDialog(
				<ElementPicker
					onSelectElement={(type, config) =>
						handleAddElement(type, config, {
							x: minX,
							y: minY,
							width,
							height,
						})
					}
					onClose={popDialog}
				/>,
			);
		}

		// Remove global event listeners
		document.removeEventListener("mousemove", handleMouseMove);
		document.removeEventListener("mouseup", handleMouseUp);
		document.removeEventListener("touchmove", handleMouseMove);
		document.removeEventListener("touchend", handleMouseUp);

		// Reset start and current positions
		setStartPosition(null);
		setCurrentPosition(null);
	};

	// Handle mouse down for rectangle selection
	const handleRoomMouseDown = (e) => {
		if (!isEditMode || level !== 0) return;

		e.preventDefault(); // Prevent default touch behavior

		if (isClickOnEmptySpace(e)) {
			// Get the room's bounding rectangle
			const roomRect = e.currentTarget.getBoundingClientRect();

			// Get the coordinates of the event (works for both mouse and touch)
			const { x: clientX, y: clientY } = getEventCoordinates(e);

			// Calculate the position relative to the room container
			let startX = Math.floor((clientX - roomRect.left) / gridFieldSize);
			let startY = Math.floor((clientY - roomRect.top) / gridFieldSize);

			// Ensure the positions are within bounds
			startX = Math.max(0, Math.min(startX, palaceWidth - 1));
			startY = Math.max(0, Math.min(startY, palaceHeight - 1));

			setStartPosition({ x: startX, y: startY });
			setCurrentPosition({ x: startX, y: startY });

			// Add global event listeners for both mouse and touch events
			document.addEventListener("mousemove", handleMouseMove);
			document.addEventListener("mouseup", handleMouseUp);
			document.addEventListener("touchmove", handleMouseMove, {
				passive: false,
			});
			document.addEventListener("touchend", handleMouseUp);
		}
	};

	const handleAddElement = async (type, config, { x, y, width, height }) => {
		const configData = await handleElementConfig(type, config);
		console.log(configData);

		updatePalaceData((draft) => {
			const room = findElementById(draft, id);
			room.elements.push({
				id: Date.now(),
				x,
				y,
				width,
				height,
				type,
				...configData,
			});
		});

		popDialog();
	};

	const renderComponent = (element) => {
		return (
			<ResizableMovableComponent
				key={element.id}
				element={element}
				isEditMode={isEditMode}
				level={level}
				handleElementPress={(e) => handleElementPress(e, element.id)}
				handleResizePress={(e) => handleResizePress(e, element.id)}
				onClick={() => {
					if (level !== 0) return;
					// Set the clicked Room as the new root with edit mode
					if (element.type === "Room") {
						setSelectedRoomId(element.id);
					}
				}}
			/>
		);
	};

	const renderRoomContent = () => (
		<div
			style={{
				width: "100%",
				height: "100%",
				backgroundImage: isEditMode
					? `linear-gradient(to right, ${currentTheme.gridColor} 1px, transparent 1px), linear-gradient(to bottom, ${currentTheme.gridColor} 1px, transparent 1px)`
					: "none",
				backgroundSize: isEditMode
					? `${gridFieldSize}px ${gridFieldSize}px`
					: "none",
				backgroundPosition: "0px 0px",
				position: "relative",
				overflow: "visible",
			}}
			onMouseDown={handleRoomMouseDown}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
			onTouchStart={handleRoomMouseDown}
			onTouchMove={handleMouseMove}
			onTouchEnd={handleMouseUp}
			onContextMenu={handleRightClick}
		>
			{roomElements.map((element) => renderComponent(element))}
			{renderSelectionRectangle()}
		</div>
	);

	return (
		<div
			className="Room"
			style={{
				width: level === 0 ? `${gridWidth + 1}px` : "100%",
				height: level === 0 ? `${gridHeight + 1}px` : "100%",
			}}
		>
			{level === 0 ? (
				isRoom3D ? (
					<Room3d
						width={palaceWidth}
						height={palaceHeight}
						gridFieldSize={gridFieldSize}
						animation={"rotate"}
					>
						{/* Render children inside Room3d if visible */}
						{renderRoomContent()}
					</Room3d>
				) : (
					// Render children directly without Room3d if not visible
					renderRoomContent()
				)
			) : (
				<RoomCover roomName={roomName} iconName={iconName} />
			)}
		</div>
	);
}

export default Room;
