import DataTableConfig from "./DataTableConfig/DataTableConfig";
import RoomConfig from "./RoomConfig/RoomConfig";
import ActionDialogConfig from "./ActionDialogConfig/ActionDialogConfig";
import CalendarConfig from "./CalendarConfig/CalendarConfig";

/* Only include elements that have configuration screens */
export const ELEMENT_CONFIGURATIONS = {
	Room: {
		configComponent: RoomConfig,
		width: "400px",
		height: "400px",
	},
	DataTable: {
		configComponent: DataTableConfig,
		width: "700px",
		height: "620px",
	},
	ActionDialog: {
		configComponent: ActionDialogConfig,
		width: "700px",
		height: "620px",
		noConfigButton: true,
	},
	Calendar: {
		configComponent: CalendarConfig,
		noConfigButton: true,
	},
};
