import "./Page.css";
import Sidebar from "./Sidebar/Sidebar";
import { useUserData } from "../../services/UserDataProvider";
import Welcome from "./Welcome/Welcome";
import Samantha from "../../Samantha/Samantha";
import React, { useState } from "react";
import { DialogProvider } from "../../services/DialogProvider";

function Page(props) {
	const { userData } = useUserData();
	const [dialogStack, setDialogStack] = useState([]);

	const pushDialog = (dialog, closable = true) => {
		setDialogStack((prevStack) => [
			...prevStack,
			{ component: dialog, closable },
		]);
	};

	const popDialog = () => {
		setDialogStack((prevStack) => prevStack.slice(0, -1));
	};

	const clearDialogStack = () => {
		setDialogStack([]);
	};

	const handleOutsideClick = (e) => {
		e.stopPropagation();

		// Check if click was on the dialog-root container
		if (e.target.classList.contains("dialog-root")) {
			const lastDialog = dialogStack[dialogStack.length - 1];
			if (lastDialog?.closable) {
				popDialog(); // Remove the last dialog if it's closable
			}
		}
	};

	return (
		<DialogProvider
			pushDialog={pushDialog}
			popDialog={popDialog}
			clearDialogStack={clearDialogStack}
			dialogStack={dialogStack}
		>
			<div className="Page">
				<Sidebar />
				<div className="Page-content" style={{ position: "relative" }}>
					<Samantha />
					{props.children}

					{dialogStack.map((dialog, index) => (
						<div
							key={index}
							className="dialog-root"
							style={{
								zIndex: 1000 + index, // Stack dialogs dynamically
							}}
							onClick={handleOutsideClick}
						>
							{dialog.component}
						</div>
					))}
				</div>
				{userData === null && <Welcome />}
			</div>
		</DialogProvider>
	);
}

export default Page;
