import React from "react";
import "./DialogPicker.css";

function DialogPicker({ children, style }) {
	return (
		<div className="DialogPicker" style={style}>
			{children}
		</div>
	);
}

export default DialogPicker;
