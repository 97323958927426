import React, { useState, useEffect, useRef } from "react";
import "./CoverDialog.css";
import { renderElement, getEventCoordinates } from "../../../../../utils/utils";
import Paper from "@mui/material/Paper";
import { coverConfig } from "../coverConfig";

function CoverDialog({
	onClose,
	element,
	updatePalaceData,
	gridFieldSize,
	palaceWidth,
	palaceHeight,
	findElementById,
}) {
	const { minWidth, minHeight, expandDefaultWidth, expandDefaultHeight } =
		coverConfig[element.type];

	const [dialogWidth, setDialogWidth] = useState(
		element.coverWidth || expandDefaultWidth,
	);
	const [dialogHeight, setDialogHeight] = useState(
		element.coverHeight || expandDefaultHeight,
	);

	const handleOutsideClick = (e) => {
		e.stopPropagation();
		if (e.target.classList.contains("CoverDialog")) {
			onClose();
		}
	};

	const handleResizeMouseDown = (e) => {
		e.stopPropagation();

		const { x: startX, y: startY } = getEventCoordinates(e);
		const startWidth = dialogWidth;
		const startHeight = dialogHeight;

		const onMouseMove = (e) => {
			const { x: currentX, y: currentY } = getEventCoordinates(e);
			let newWidth =
				startWidth +
				2 * Math.round((currentX - startX) / gridFieldSize);
			let newHeight =
				startHeight +
				2 * Math.round((currentY - startY) / gridFieldSize);

			newWidth = Math.max(minWidth, newWidth);
			newHeight = Math.max(minHeight, newHeight);
			newWidth = Math.min(newWidth, palaceWidth);
			newHeight = Math.min(newHeight, palaceHeight);

			setDialogWidth(newWidth);
			setDialogHeight(newHeight);

			updatePalaceData((draft) => {
				const palaceElement = findElementById(draft, element.id);
				palaceElement.coverWidth = newWidth;
				palaceElement.coverHeight = newHeight;
			});
		};

		const onMouseUp = () => {
			document.removeEventListener("mousemove", onMouseMove);
			document.removeEventListener("mouseup", onMouseUp);
			document.removeEventListener("touchmove", onMouseMove);
			document.removeEventListener("touchend", onMouseUp);
		};

		document.addEventListener("mousemove", onMouseMove);
		document.addEventListener("mouseup", onMouseUp);
		document.addEventListener("touchmove", onMouseMove, { passive: false });
		document.addEventListener("touchend", onMouseUp);
	};

	return (
		<div className="CoverDialog" onMouseDown={handleOutsideClick}>
			<div
				className="CoverDialog-box"
				style={{
					width: `${dialogWidth * gridFieldSize}px`,
					height: `${dialogHeight * gridFieldSize}px`,
				}}
			>
				<Paper
					elevation={8}
					style={{
						width: `${dialogWidth * gridFieldSize}px`,
						height: `${dialogHeight * gridFieldSize}px`,
						position: "absolute",
						borderRadius:
							element.width === 1 || element.height === 1
								? "5px"
								: "16px",
						padding: "0px",
					}}
				>
					<div
						style={{
							width: "100%",
							height: "100%",
							borderRadius:
								element.width === 1 || element.height === 1
									? "5px"
									: "16px",
							overflow: "visible",
							boxSizing: "border-box",
						}}
					>
						{renderElement(element)}
					</div>
					<div
						className="CoverDialog-resizeHandle"
						onMouseDown={handleResizeMouseDown}
						onTouchStart={handleResizeMouseDown}
					/>
				</Paper>
			</div>
		</div>
	);
}

export default CoverDialog;
