import React, { useEffect, useState } from "react";
import CommandLine from "./CommandLine/CommandLine";
import { useSamantha } from "../../../../services/SamanthaProvider";
import "./AIDialog.css";

function AiDialog({}) {
	const { showSamantha, hideSamantha } = useSamantha();
	const [text, setText] = useState("What's up, buttercup?");

	useEffect(() => {
		showSamantha([text], {
			stayOpen: true,
		});
		return () => {
			hideSamantha();
		};
	}, [text]);

	return (
		<div className="AIDialog">
			<CommandLine showResponse={setText} />
		</div>
	);
}

export default AiDialog;
