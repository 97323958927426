import "./ThoughtPalaceApp.css";
import { Routes, Route } from "react-router-dom";
import Page from "./Page/Page";
import Abilities from "./views/Abilities/Abilities";
import Payments from "./views/Payments/Payments";
import Palace from "./views/Palace/Palace";

function ThoughtPalaceApp() {
	return (
		<Page>
			<Routes>
				<Route path="/" element={<Palace />} />
				<Route path="/abilities" element={<Abilities />} />
				<Route path="/payments" element={<Payments />} />
			</Routes>
		</Page>
	);
}

export default ThoughtPalaceApp;
