import "./global.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StartPage from "./StartPage/StartPage";
import { AuthenticationGuard } from "./Authentication/AuthenticationGuard";
import ThoughtPalaceApp from "./ThoughtPalaceApp/ThoughtPalaceApp";
import { useEffect } from "react";
import Palace from "./ThoughtPalaceApp/views/Palace/Palace";

function App() {
	useEffect(() => {
		// Prevent default drag-and-drop behavior for the entire window
		const handleDragOver = (e) => {
			e.preventDefault();
			e.dataTransfer.dropEffect = "none"; // Prevents the + sign
		};

		const handleDrop = (e) => {
			e.preventDefault();
			e.dataTransfer.dropEffect = "none";
		};

		window.addEventListener("dragover", handleDragOver);
		window.addEventListener("drop", handleDrop);

		// Cleanup event listeners when the component unmounts
		return () => {
			window.removeEventListener("dragover", handleDragOver);
			window.removeEventListener("drop", handleDrop);
		};
	}, []);

	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<StartPage />} />
					<Route
						path="/thought-palace/*"
						element={
							<AuthenticationGuard component={ThoughtPalaceApp} />
						}
					/>
					<Route
						path="/mobile-palace/*"
						element={
							<Palace
								gridFieldSize={30}
								palaceWidth={12}
								palaceHeight={16}
							/>
						}
					/>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
