// CalendarConfig.js
import React, { useEffect, useState } from "react";
import "./CalendarConfig.css";
import MultiStageDialog from "../../../Dialog/MultiStageDialog/MultiStageDialog";
import CalendarInfoStep from "./CalendarInfoStep/CalendarInfoStep";
import ChooseExistingCalendarStep from "./ChooseExistingCalendarStep/ChooseExistingCalendarStep";
import PickerStep from "./PickerStep/PickerStep";
import { usePalaceConfigContext } from "../../../../../../services/PalaceConfigProvider";
import { useDialogContext } from "../../../../../../services/DialogProvider";

export default function CalendarConfig({ onConfigurationComplete }) {
	const { popDialog } = useDialogContext();

	const steps = {
		picker: PickerStep,
		calendarInfoStep: CalendarInfoStep,
		chooseExistingCalendarStep: ChooseExistingCalendarStep,
	};

	const handleClose = (finalData) => {
		popDialog();
		console.log("final-data:" + finalData);
		onConfigurationComplete(finalData);
	};

	return (
		<div className="CalendarConfig">
			<MultiStageDialog
				onClose={handleClose} // called from MultiStageDialog when flow ends
				steps={steps}
				initialStep="picker"
			/>
		</div>
	);
}
