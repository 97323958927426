import React, { createContext, useContext } from "react";

const AccessoryInputContext = createContext();

export const AccessoryInputProvider = ({ children }) => {
	const isMultiSelectModifierPressed = (event) => {
		return event.ctrlKey || event.metaKey; // Handle both Windows (Ctrl) and Mac (Cmd)
	};
	return (
		<AccessoryInputContext.Provider
			value={{
				isMultiSelectModifierPressed,
			}}
		>
			{children}
		</AccessoryInputContext.Provider>
	);
};

export const useAccessoryInputContext = () => useContext(AccessoryInputContext);

export default AccessoryInputContext;
