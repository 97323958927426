import React, { useEffect, useState } from "react";
import "./Samantha.css";
import TypeIt from "typeit-react";
import { useSamantha } from "../services/SamanthaProvider";

function Samantha({ width = "1000px" }) {
	const { isVisible, text, stayOpen, hideSamantha } = useSamantha();

	const style = {
		width,
		display: isVisible ? "flex" : "none", // Hide when not visible
	};

	return (
		<div className="samantha" style={style}>
			<div className="samantha-content">
				<div className="samantha-blob-wrapper">
					<div className="samantha-blob"></div>
				</div>
				{/* Assign a key based on the text to force re-render */}
				<TypeIt
					key={text}
					as="h1"
					options={{
						strings: text,
						speed: 40,
						waitUntilVisible: false,
						nextStringDelay: 1000,
					}}
					className="heading actor__content"
					style={{
						fontSize: "2rem",
						fontWeight: "300",
						color: "#eee",
						textAlign: "left",
					}}
				/>
			</div>
		</div>
	);
}

export default Samantha;
