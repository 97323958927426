import React, { useEffect, useState } from "react";
import {
	Box,
	Typography,
	List,
	ListItem,
	ListItemText,
	Tooltip,
	CircularProgress,
} from "@mui/material";
import { useAccessToken } from "../../../../../../../services/AccessTokenProvider";
import { useSamantha } from "../../../../../../../services/SamanthaProvider";

export default function ChooseExistingCalendarStep({ onNext }) {
	const { accessToken } = useAccessToken();
	const [loading, setLoading] = useState(true);
	const [calendars, setCalendars] = useState([]);
	const { showSamantha, hideSamantha } = useSamantha();

	useEffect(() => {
		showSamantha(["Choose existing Calendar"], {
			stayOpen: true,
		});
		return () => {
			hideSamantha();
		};
	}, []);

	useEffect(() => {
		const fetchCalendars = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/get-list-of-calendars`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);
				if (response.ok) {
					const data = await response.json();

					setCalendars(data.response);
				} else {
					console.error("Failed to fetch calendars");
				}
			} catch (error) {
				console.error("Error fetching calendars:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchCalendars();
	}, [accessToken]);

	const handleItemSelect = (id) => {
		// Select a calendar and close the dialog
		onNext(null, { selectedCalendarId: id });
	};

	if (loading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="200px"
			>
				<CircularProgress style={{ color: "white" }} />
			</Box>
		);
	}

	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<List sx={{ bgcolor: "transparent", color: "white", padding: 0 }}>
				{calendars.map((item) => (
					<Tooltip
						key={item._id}
						title={item.description || "No description available"}
						placement="right"
					>
						<ListItem
							button
							onClick={() => handleItemSelect(item._id)}
							sx={{
								"&:hover": {
									backgroundColor: "rgba(255,255,255,0.1)",
								},
							}}
						>
							<ListItemText
								primary={item.name}
								sx={{ color: "white" }}
							/>
						</ListItem>
					</Tooltip>
				))}
			</List>
		</Box>
	);
}
