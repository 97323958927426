import React, { useState, useEffect } from "react";
import "./Palace.css";
import Room from "./Room/Room";
import { produce } from "immer";
import { useAccessToken } from "../../../services/AccessTokenProvider";
import { useThemeContext } from "../../../services/ThemeProvider";
import Toolbar from "./Toolbar/Toolbar";
import { PalaceDataProvider } from "../../../services/PalaceConfigProvider";
import { useDialogContext } from "../../../services/DialogProvider";

function Palace({ gridFieldSize = 30, palaceWidth = 41, palaceHeight = 25 }) {
	const { themeMode, toggleThemeMode, getThemeForComponent } =
		useThemeContext();
	const currentTheme = getThemeForComponent("palace");
	const { clearDialogStack } = useDialogContext();

	const { accessToken } = useAccessToken();
	const [isEditMode, setIsEditMode] = useState(false);

	const [palace, setPalace] = useState(null);
	const [selectedRoomId, setSelectedRoomId] = useState(null);

	useEffect(() => {
		async function fetchData() {
			const fetchedPalace = await fetchPalace();

			setPalace(fetchedPalace.palace);
			setSelectedRoomId(fetchedPalace.palace.id);
		}

		if (accessToken) {
			fetchData();
		}
	}, [accessToken]);

	useEffect(() => {
		clearDialogStack();
	}, [selectedRoomId]);

	const fetchPalace = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/palaces/get-palace`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
				},
			);

			return await response.json();
		} catch (error) {
			console.error("Error fetching palace:", error);
		}
	};

	const updatePalaceData = (recipe) => {
		setPalace((currentPalace) => produce(currentPalace, recipe));
	};

	const updatePalaceDataWithSave = (recipe) => {
		setPalace((currentPalace) => {
			const newPalace = produce(currentPalace, recipe);
			savePalace(newPalace); // Save the updated state immediately
			return newPalace;
		});
	};

	const findElementById = (element, id) => {
		if (element.id === id) return element;
		if (element.elements) {
			for (let child of element.elements) {
				const found = findElementById(child, id);
				if (found) return found;
			}
		}
		return null;
	};

	// Function to get breadcrumbs path from palace to selected room
	const getBreadcrumbsPath = (room, targetId) => {
		if (room.id === targetId) {
			return [room];
		}
		if (room.elements) {
			for (let element of room.elements) {
				if (element.type === "Room") {
					let path = getBreadcrumbsPath(element, targetId);
					if (path) {
						return [room, ...path];
					}
				}
			}
		}
		return null;
	};

	// Find the parent room recursively by traversing palaceData
	const findParentRoomId = (root, childId) => {
		if (!root.elements) return null;

		for (let element of root.elements) {
			if (element.id === childId) return root.id;

			const parentId = findParentRoomId(element, childId);

			if (parentId) return parentId;
		}
		return null;
	};

	// Function to handle going back to the parent room
	const goToParentRoom = (currentRoomId) => {
		const parentRoomId = findParentRoomId(palace, currentRoomId);

		if (parentRoomId !== null) {
			setSelectedRoomId(parentRoomId);
		}
	};

	const selectedRoom = palace
		? findElementById(palace, selectedRoomId)
		: null;

	// Generate breadcrumbs based on the selected room
	const breadcrumbsPath = palace
		? getBreadcrumbsPath(palace, selectedRoomId)
		: [];

	breadcrumbsPath.map(function (room) {
		console.log("Romm Design" + room);
		return {
			key: room.id,
			label: room.roomName || "Home",
			iconName: room.icon || "HomeIcon",
			id: room.id, // Include the room ID for navigation
		};
	});

	const breadcrumbs = breadcrumbsPath
		? breadcrumbsPath.map((room) => ({
				key: room.id,
				label: room.roomName || "Home",
				iconName: room.icon || "HomeIcon",
				id: room.id, // Include the room ID for navigation
			}))
		: [];

	const toggleEditMode = () => {
		setIsEditMode((prevMode) => {
			if (prevMode === true) {
				savePalace();
			}
			return !prevMode;
		});
	};

	const clearPalace = () => {
		setPalace((currentPalace) =>
			produce(currentPalace, (draft) => {
				draft.elements = [];
			}),
		);
		setSelectedRoomId(palace.id);
		savePalace();
	};

	// Save the current palace data
	const savePalace = async (palaceToSave = palace) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/palaces/save-palace`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify({ palace: palaceToSave }),
				},
			);

			if (!response.ok) {
				console.error("Failed to save palace");
			}
		} catch (error) {
			console.error("Error saving palace:", error);
		}
	};

	useEffect(() => {
		const handleKeyPress = (event) => {
			const keyActions = {};
			const action = keyActions[event.key];
			if (action) action();
		};

		window.addEventListener("keydown", handleKeyPress);
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [palace]);

	// Handler for breadcrumb click
	const handleBreadcrumbClick = (roomId) => {
		setSelectedRoomId(roomId);
	};

	return (
		<PalaceDataProvider
			gridFieldSize={gridFieldSize}
			palaceWidth={palaceWidth}
			palaceHeight={palaceHeight}
			updatePalaceData={updatePalaceData}
			updatePalaceDataWithSave={updatePalaceDataWithSave}
			findElementById={findElementById}
		>
			<div
				className="Palace"
				style={{ backgroundColor: currentTheme.backgroundColor }}
			>
				<div className="ToolbarContainer">
					<Toolbar
						roomname={
							selectedRoom
								? selectedRoom.roomName || selectedRoom.name
								: "Loading..."
						}
						breadcrumbs={breadcrumbs}
						roomName={
							selectedRoom
								? selectedRoom.roomName || selectedRoom.name
								: "Loading..."
						}
						isEditMode={isEditMode}
						themeMode={themeMode}
						toggleEditMode={toggleEditMode}
						clearPalace={clearPalace}
						toggleThemeMode={toggleThemeMode}
						onBreadcrumbClick={handleBreadcrumbClick} // Pass the click handler
					/>
				</div>

				{/* Room Container */}
				<div className="RoomContainer">
					{selectedRoom && (
						<Room
							key={selectedRoom.id}
							id={selectedRoom.id}
							isEditMode={isEditMode}
							elements={selectedRoom.elements}
							level={0}
							setSelectedRoomId={setSelectedRoomId}
							goToParentRoom={goToParentRoom}
						/>
					)}
				</div>
			</div>
		</PalaceDataProvider>
	);
}

export default Palace;
