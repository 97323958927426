import React from "react";
import AppBar from "@mui/material/AppBar";
import MUI_Toolbar from "@mui/material/Toolbar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import * as MuiIcons from "@mui/icons-material";
import "./Toolbar.css";
import {
	Edit as EditIcon,
	Clear as ClearIcon,
	Brightness4 as ThemeIcon,
} from "@mui/icons-material";
import { Typography } from "@mui/material";

function Toolbar({
	breadcrumbs,
	toggleEditMode,
	clearPalace,
	toggleThemeMode,
	onBreadcrumbClick, // Accept the click handler
	roomName,
}) {
	const StyledBreadcrumb = styled(Chip)({
		backgroundColor: "#33333", // Light background color
		height: "24px", // Set height explicitly
		color: "white", // Dark text color
		fontWeight: 400, // Normal font weight
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "grey",
		},
	});

	const breadcrumbsItems = breadcrumbs.map((breadcrumb) => {
		console.log("This is the key" + breadcrumb);
		const IconComponent = MuiIcons[breadcrumb.iconName];

		return (
			<StyledBreadcrumb
				key={breadcrumb.key}
				component="div"
				label={breadcrumb.label}
				icon={
					IconComponent ? (
						<IconComponent
							fontSize="small"
							style={{ color: "#FFFFFF" }}
						/>
					) : null
				}
				onClick={() => onBreadcrumbClick(breadcrumb.id)} // Attach click handler
				sx={{
					color: "#FFFFFF",
					backgroundColor: "#333333",
					padding: "15px 5px",
					border: "1px solid white",
					cursor: "pointer",
				}}
			/>
		);
	});
	console.log(breadcrumbsItems);

	return (
		<AppBar
			position="static"
			sx={{
				backgroundColor: "#333333",
				borderRadius: "80px",
				margin: "15px 30px",
				width: "calc(100% - 60px)",
				boxSizing: "border-box",
			}}
		>
			<MUI_Toolbar
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				{/* Breadcrumbs on the left */}
				<Box
					sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
				>
					<Breadcrumbs
						aria-label="breadcrumb"
						separator={
							<NavigateNextIcon
								fontSize="small"
								sx={{ color: "#FFFFFF" }}
							/>
						}
						sx={{ color: "white" }}
					>
						{breadcrumbsItems}
					</Breadcrumbs>
				</Box>

				{/* Typography centered */}
				<Typography
					variant="h4"
					className="title"
					style={{
						color: "white",
						textAlign: "center",
					}}
					sx={{
						position: "absolute",
						left: "50%",
						transform: "translateX(-50%)",
					}}
				>
					{roomName}
				</Typography>

				{/* Icon buttons on the right */}
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<IconButton
						color="inherit"
						onClick={toggleEditMode}
						sx={{ borderRadius: "8px" }}
					>
						<EditIcon />
					</IconButton>
					<IconButton
						color="inherit"
						onClick={clearPalace}
						sx={{
							borderRadius: "8px",
							ml: 1,
						}}
					>
						<ClearIcon />
					</IconButton>
					<IconButton
						color="inherit"
						onClick={toggleThemeMode}
						sx={{
							borderRadius: "8px",
							ml: 1,
						}}
					>
						<ThemeIcon />
					</IconButton>
				</Box>
			</MUI_Toolbar>
		</AppBar>
	);
}

export default Toolbar;
