import React, { createContext, useContext } from "react";

const DialogContext = createContext();

export const DialogProvider = ({
	children,
	pushDialog,
	popDialog,
	clearDialogStack,
	dialogStack,
}) => {
	return (
		<DialogContext.Provider
			value={{
				pushDialog,
				popDialog,
				clearDialogStack,
				dialogStack,
			}}
		>
			{children}
		</DialogContext.Provider>
	);
};

export const useDialogContext = () => useContext(DialogContext);

export default DialogContext;
