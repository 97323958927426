import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import { RRule, RRuleSet } from "rrule";
import "./Calendar.css";
import CalendarEventDialog from "./CalendarEventDialog/CalendarEventDialog";
import { usePalaceConfigContext } from "../../../../../services/PalaceConfigProvider";
import OptionsDialog from "./OptionsDialog/OptionsDialog";
import { useAccessToken } from "../../../../../services/AccessTokenProvider";
import { useDialogContext } from "../../../../../services/DialogProvider";
import { useAccessoryInputContext } from "../../../../../services/AccessoryInputProvider";

function Calendar({ id: elementId, calendarId }) {
	const palaceConfigContext = usePalaceConfigContext();
	const { isMultiSelectModifierPressed } = useAccessoryInputContext();

	const { pushDialog, popDialog } = useDialogContext();
	const { accessToken } = useAccessToken();

	const [events, setEvents] = useState([]);
	const [selectedEvents, setSelectedEvents] = useState([]);
	const [isCtrlPressed, setIsCtrlPressed] = useState(false);
	const eventsRef = useRef(events);
	const selectedEventsRef = useRef(selectedEvents);
	const calendarRef = useRef(null);

	useEffect(() => {
		eventsRef.current = events;
		selectedEventsRef.current = selectedEvents;
	}, [events, selectedEvents]);

	useEffect(() => {
		const fetchCalendarData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/get-calendar?calendarId=${calendarId}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);

				if (response.ok) {
					const data = await response.json();
					setEvents(data.events || []);
				} else {
					console.error("Failed to fetch calendar data");
				}
			} catch (error) {
				console.error("Error fetching calendar data:", error);
			}
		};

		fetchCalendarData();
	}, []);

	useEffect(() => {
		function handleKeyDown(e) {
			if (isMultiSelectModifierPressed(e)) {
				setIsCtrlPressed(true);
			}
		}

		function handleKeyUp(e) {
			if (!isMultiSelectModifierPressed(e)) {
				setIsCtrlPressed(false);
			}
		}

		window.addEventListener("keydown", handleKeyDown);
		window.addEventListener("keyup", handleKeyUp);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
			window.removeEventListener("keyup", handleKeyUp);
		};
	}, []);

	useEffect(() => {
		if (calendarRef.current) {
			const eventEls = calendarRef.current.querySelectorAll(".fc-event");
			eventEls.forEach((el) => {
				const eventId = el.getAttribute("data-event-id");

				// Add or remove the selected class based on state
				if (selectedEvents.includes(eventId)) {
					el.classList.add("selected-event");
				} else {
					el.classList.remove("selected-event");
				}
			});
		}
	}, [selectedEvents]);

	const saveCalendarData = async (updatedEvents) => {
		try {
			const calendarData = {
				_id: calendarId,
				events: updatedEvents,
			};

			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/thought-palace/save-calendar`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify({ calendarData }),
				},
			);

			if (!response.ok) {
				console.error("Failed to save calendar");
			}
		} catch (error) {
			console.error("Error saving calendar data:", error);
		}
	};

	const handleEventSave = (eventData) => {
		const { type } = eventData;
		const existingEventIndex = events.findIndex(
			(event) => event.id === eventData.id,
		);
		const existingEvent = events[existingEventIndex];
		let updatedEvents = [];

		if (type === "present") {
			// Change only this event
			if (existingEvent) {
				// Adjust the time of eventData.start to match existingEvent.start
				const eventStart = new Date(eventData.start);
				const existingStart = new Date(existingEvent.start);

				// Set the time of eventData.start to match existingEvent.start
				eventStart.setHours(
					existingStart.getHours(),
					existingStart.getMinutes(),
					existingStart.getSeconds(),
					existingStart.getMilliseconds(),
				);

				// Add the date to exdate
				existingEvent.exdate = existingEvent.exdate || [];
				const excludedDate = existingEvent.allDay
					? formatCalendarDate(eventStart, true) // For allDay: YYYY-MM-DD
					: eventStart.toISOString(); // For timed events: full UTC timestamp

				existingEvent.exdate.push(excludedDate);

				// Create a new event for the selected date
				const newEvent = {
					id: String(new Date().getTime()),
					title: eventData.title,
					start: eventData.start,
					end: eventData.end,
					allDay: eventData.allDay,
					extendedProps: {
						repeat: eventData.repeat,
						repeatInDays:
							eventData.repeat === "Custom"
								? eventData.repeatInDays
								: null,
						endRepeat: eventData.endRepeat,
						endRepeatDate: eventData.endRepeatDate,
					},
				};

				if (eventData.repeat !== "Never") {
					let ruleOptions = {
						freq: RRule.DAILY,
						dtstart: new Date(eventData.start),
					};

					// Set frequency and interval based on the repeat option
					switch (eventData.repeat) {
						case "Every Day":
							ruleOptions.freq = RRule.DAILY;
							break;
						case "Every Week":
							ruleOptions.freq = RRule.WEEKLY;
							break;
						case "Every Month":
							ruleOptions.freq = RRule.MONTHLY;
							break;
						case "Every Year":
							ruleOptions.freq = RRule.YEARLY;
							break;
						case "Custom":
							ruleOptions.freq = RRule.DAILY;
							ruleOptions.interval = parseInt(
								eventData.repeatInDays,
								10,
							);
							break;
						default:
							break;
					}

					if (
						eventData.endRepeat === "On Date" &&
						eventData.endRepeatDate
					) {
						const untilDate = new Date(eventData.endRepeatDate);
						untilDate.setHours(23, 59, 59, 999);
						ruleOptions.until = untilDate;
					}

					const startDate = new Date(eventData.start);
					const endDate = new Date(eventData.end);

					const durationMilliseconds = endDate - startDate;

					newEvent.rrule = new RRule(ruleOptions).toString();
					newEvent.duration = {
						milliseconds: durationMilliseconds,
					};
					newEvent.end = undefined;
				} else {
					newEvent.duration = undefined;
					newEvent.end = eventData.end;
				}

				updatedEvents = [...events];
				updatedEvents[existingEventIndex] = existingEvent;
				updatedEvents.push(newEvent);
			}
		} else if (type === "future") {
			let existingDate = new Date(existingEvent.start);
			let newDate = new Date(eventData.start);

			// Function to check if a date is in the excluded dates
			function isExcluded(date, excludedDates) {
				const formattedDate = formatCalendarDate(date, true); // Format as YYYY-MM-DD
				return excludedDates.includes(formattedDate);
			}

			// Iterate to find the next non-excluded date
			while (
				existingEvent.exdate &&
				isExcluded(existingDate, existingEvent.exdate)
			) {
				existingDate.setDate(existingDate.getDate() + 1); // Move to the next day
			}

			if (isSameDay(existingDate, newDate)) {
				// Remove the existing event and just add the new event
				const newEvent = {
					id: String(new Date().getTime()),
					title: eventData.title,
					allDay: eventData.allDay,
					start: eventData.start,
					end: eventData.end,
					extendedProps: {
						repeat: eventData.repeat,
						repeatInDays: eventData.repeatInDays,
						endRepeat: eventData.endRepeat,
						endRepeatDate: eventData.endRepeatDate,
					},
				};

				// Build the RRule for the new event
				let newRuleOptions = {
					freq: RRule.DAILY,
					dtstart: new Date(eventData.start),
				};

				// Set frequency and interval based on the repeat option
				switch (eventData.repeat) {
					case "Every Day":
						newRuleOptions.freq = RRule.DAILY;
						break;
					case "Every Week":
						newRuleOptions.freq = RRule.WEEKLY;
						break;
					case "Every Month":
						newRuleOptions.freq = RRule.MONTHLY;
						break;
					case "Every Year":
						newRuleOptions.freq = RRule.YEARLY;
						break;
					case "Custom":
						newRuleOptions.freq = RRule.DAILY;
						newRuleOptions.interval = parseInt(
							eventData.repeatInDays,
						);
						break;
					default:
						break;
				}

				if (
					eventData.endRepeat === "On Date" &&
					eventData.endRepeatDate
				) {
					const untilDate = new Date(eventData.endRepeatDate);
					untilDate.setHours(23, 59, 59, 999);
					newRuleOptions.until = untilDate;
				}

				if (eventData.repeat !== "Never") {
					newEvent.rrule = new RRule(newRuleOptions).toString();
					const startDate = new Date(eventData.start);
					const endDate = new Date(eventData.end);
					const durationMilliseconds = endDate - startDate;

					newEvent.duration = {
						milliseconds: durationMilliseconds,
					};
					newEvent.end = undefined;
				} else {
					newEvent.duration = undefined;
					newEvent.end = eventData.end;
				}

				// Replace the existing event with the new event
				updatedEvents = [...events];
				updatedEvents.splice(existingEventIndex, 1, newEvent);
				setEvents(updatedEvents);
			} else {
				// Change all future events
				if (existingEvent) {
					// Adjust existing event's RRule to end before the selected date
					const rruleOptions = RRule.parseString(existingEvent.rrule);
					const untilDate = new Date(eventData.start);
					untilDate.setDate(untilDate.getDate() - 1);
					untilDate.setHours(23, 59, 59, 999);
					rruleOptions.until = untilDate;
					existingEvent.rrule = new RRule(rruleOptions).toString();

					// Adjust the endRepeat and endRepeatDate based on the new RRule
					existingEvent.extendedProps.endRepeat = "On Date";
					existingEvent.extendedProps.endRepeatDate = untilDate
						.toISOString()
						.split("T")[0];

					// Create a new event starting from the selected date
					const newEvent = {
						id: String(new Date().getTime()),
						title: eventData.title,
						allDay: eventData.allDay,
						start: eventData.start,
						end: eventData.end,
						extendedProps: {
							repeat: eventData.repeat,
							repeatInDays: eventData.repeatInDays,
							endRepeat: eventData.endRepeat,
							endRepeatDate: eventData.endRepeatDate,
						},
					};

					// Build the rrule for the new event
					let newRuleOptions = {
						freq: RRule.DAILY,
						dtstart: new Date(eventData.start),
					};

					// Set frequency and interval based on the repeat option
					switch (eventData.repeat) {
						case "Every Day":
							newRuleOptions.freq = RRule.DAILY;
							break;
						case "Every Week":
							newRuleOptions.freq = RRule.WEEKLY;
							break;
						case "Every Month":
							newRuleOptions.freq = RRule.MONTHLY;
							break;
						case "Every Year":
							newRuleOptions.freq = RRule.YEARLY;
							break;
						case "Custom":
							newRuleOptions.freq = RRule.DAILY;
							newRuleOptions.interval = parseInt(
								eventData.repeatInDays,
							);
							break;
						default:
							break;
					}

					if (
						eventData.endRepeat === "On Date" &&
						eventData.endRepeatDate
					) {
						const untilDate = new Date(eventData.endRepeatDate);
						untilDate.setHours(23, 59, 59, 999);
						newRuleOptions.until = untilDate;
					}

					if (eventData.repeat !== "Never") {
						newEvent.rrule = new RRule(newRuleOptions).toString();
						const startDate = new Date(eventData.start);
						const endDate = new Date(eventData.end);
						const durationMilliseconds = endDate - startDate;

						newEvent.duration = {
							milliseconds: durationMilliseconds,
						};
						newEvent.end = undefined;
					} else {
						newEvent.duration = undefined;
						newEvent.end = eventData.end;
					}

					updatedEvents = [...events];
					updatedEvents[existingEventIndex] = existingEvent;
					updatedEvents.push(newEvent);
					setEvents(updatedEvents);
				}
			}
		} else {
			// Normal save for non-recurring events or new events
			const newEvent = {
				id: eventData.id || String(new Date().getTime()),
				title: eventData.title,
				start: eventData.start,
				allDay: eventData.allDay,
				extendedProps: {
					repeat: eventData.repeat,
					repeatInDays: eventData.repeatInDays,
					endRepeat: eventData.endRepeat,
					endRepeatDate: eventData.endRepeatDate,
				},
			};

			if (eventData.repeat !== "Never") {
				let ruleOptions = {
					freq: RRule.DAILY,
					dtstart: new Date(eventData.start),
				};

				switch (eventData.repeat) {
					case "Every Day":
						ruleOptions.freq = RRule.DAILY;
						break;
					case "Every Week":
						ruleOptions.freq = RRule.WEEKLY;
						break;
					case "Every Month":
						ruleOptions.freq = RRule.MONTHLY;
						break;
					case "Every Year":
						ruleOptions.freq = RRule.YEARLY;
						break;
					case "Custom":
						ruleOptions.freq = RRule.DAILY;
						ruleOptions.interval = parseInt(eventData.repeatInDays);
						break;
					default:
						break;
				}

				if (
					eventData.endRepeat === "On Date" &&
					eventData.endRepeatDate
				) {
					const untilDate = new Date(eventData.endRepeatDate);
					untilDate.setHours(23, 59, 59, 999);
					ruleOptions.until = untilDate;
				}

				newEvent.rrule = new RRule(ruleOptions).toString();

				const startDate = new Date(eventData.start);
				const endDate = new Date(eventData.end);
				const durationMilliseconds = endDate - startDate;

				newEvent.duration = {
					milliseconds: durationMilliseconds,
				};
				newEvent.end = undefined;
			} else {
				newEvent.duration = undefined;
				newEvent.end = eventData.end;
			}

			updatedEvents = events.some((event) => event.id === eventData.id)
				? events.map((event) =>
						event.id === eventData.id ? newEvent : event,
					)
				: [...events, newEvent];
		}
		setEvents(updatedEvents);
		saveCalendarData(updatedEvents);
	};

	const handleDateSelect = (selectInfo) => {
		pushDialog(
			<CalendarEventDialog
				onClose={popDialog}
				selectInfo={selectInfo}
				onSave={handleEventSave}
			/>,
		);
	};

	const handleEventClick = (clickInfo) => {
		if (isCtrlPressed) {
			const eventId = clickInfo.event.id;
			setSelectedEvents((prevSelectedEvents) => {
				if (prevSelectedEvents.includes(eventId)) {
					return prevSelectedEvents.filter((id) => id !== eventId);
				} else {
					return [...prevSelectedEvents, eventId];
				}
			});
		} else {
			const start = clickInfo.event.start
				? formatCalendarDate(
						clickInfo.event.start,
						clickInfo.event.allDay,
					)
				: null;

			let end = clickInfo.event.end
				? formatCalendarDate(
						clickInfo.event.end,
						clickInfo.event.allDay,
					)
				: null;

			if (clickInfo.event.allDay && end) {
				const endDate = new Date(end);
				endDate.setDate(endDate.getDate() - 1);
				end = formatCalendarDate(endDate, true);
			}

			const eventData = {
				id: clickInfo.event.id,
				title: clickInfo.event.title,
				allDay: clickInfo.event.allDay,
				start,
				end,
				repeat: clickInfo.event.extendedProps.repeat || "Never",
				repeatInDays: clickInfo.event.extendedProps.repeatInDays || 1,
				endRepeat: clickInfo.event.extendedProps.endRepeat || "Never",
				endRepeatDate:
					clickInfo.event.extendedProps.endRepeatDate || "",
			};

			pushDialog(
				<CalendarEventDialog
					onClose={popDialog}
					selectInfo={clickInfo}
					onSave={handleEventSave}
					initialEvent={eventData}
				/>,
			);
		}
	};

	const handleEventRightClick = (info, e) => {
		e.preventDefault();

		const currentEvents = eventsRef.current;
		const currentSelectedEvents = selectedEventsRef.current;

		const eventId = info.event.id;

		// Find the full event object in the `events` state
		const repeatingEvent = currentEvents.find((eve) => eve.id === eventId);

		const isRecurring = !!repeatingEvent.rrule;

		const eventIdsToDelete = [...currentSelectedEvents];
		if (!eventIdsToDelete.includes(repeatingEvent.id)) {
			eventIdsToDelete.push(repeatingEvent.id);
		}

		const eventsToDelete = currentEvents.filter((e) =>
			eventIdsToDelete.includes(e.id),
		);

		pushDialog(
			<OptionsDialog
				onClose={popDialog}
				repeatingEvent={repeatingEvent}
				isRecurring={isRecurring}
				onEdit={() => {
					popDialog();
					handleEventClick(info);
				}}
				onDelete={(deleteType) => {
					popDialog();
					handleEventDelete(eventsToDelete, deleteType, info.event);
				}}
				selectedEvents={currentSelectedEvents}
			/>,
		);
	};

	const handleEventDelete = (
		eventsToDelete,
		deleteType,
		rightClickedEvent,
	) => {
		const currentEvents = eventsRef.current;
		let updatedEvents = currentEvents.map((event) => ({ ...event })); // Clone events to avoid mutating state

		eventsToDelete.forEach((eventToDelete) => {
			const existingEventIndex = updatedEvents.findIndex(
				(e) => e.id === eventToDelete.id,
			);

			if (existingEventIndex !== -1) {
				const existingEvent = updatedEvents[existingEventIndex];

				if (eventToDelete.rrule) {
					if (deleteType === "present") {
						const eventStart = new Date(rightClickedEvent.start);
						const existingEventIndex = events.findIndex(
							(eve) => eve.id === eventToDelete.id,
						);
						const existingStart = new Date(existingEvent.start);

						// Set the time of eventData.start to match existingEvent.start
						eventStart.setHours(
							existingStart.getHours(),
							existingStart.getMinutes(),
							existingStart.getSeconds(),
							existingStart.getMilliseconds(),
						);

						// Add the date to exdate
						existingEvent.exdate = existingEvent.exdate || [];
						const excludedDate = existingEvent.allDay
							? formatCalendarDate(eventStart, true) // For allDay: YYYY-MM-DD
							: eventStart.toISOString(); // For timed events: full UTC timestamp

						existingEvent.exdate.push(excludedDate);

						if (wasLastRepeatingEventEntry(existingEvent)) {
							// No more occurrences, remove the event
							updatedEvents = updatedEvents.filter(
								(e) => e.id !== eventToDelete.id,
							);
						} else {
							// Update the event with the new exdate
							updatedEvents[existingEventIndex] = existingEvent;
						}
					} else if (deleteType === "future") {
						// Adjust the rrule to end before the reference date
						const rruleOptions = RRule.parseString(
							existingEvent.rrule,
						);
						const untilDate = new Date(rightClickedEvent.start);
						untilDate.setDate(untilDate.getDate() - 1); // End before the selected day
						untilDate.setHours(23, 59, 59, 999); // End at the last second
						rruleOptions.until = untilDate;
						existingEvent.rrule = new RRule(
							rruleOptions,
						).toString();

						// Adjust extendedProps for clarity
						existingEvent.extendedProps.endRepeat = "On Date";
						existingEvent.extendedProps.endRepeatDate = untilDate
							.toISOString()
							.split("T")[0];

						if (wasLastRepeatingEventEntry(existingEvent)) {
							// No more occurrences, remove the event
							updatedEvents = updatedEvents.filter(
								(e) => e.id !== eventToDelete.id,
							);
						} else {
							// Update the event in place
							updatedEvents[existingEventIndex] = existingEvent;
						}
					} else if (deleteType === "all") {
						// Remove the entire recurring event
						updatedEvents = updatedEvents.filter(
							(e) => e.id !== eventToDelete.id,
						);
					}
				} else {
					// For non-recurring events, simply remove the event
					updatedEvents = updatedEvents.filter(
						(e) => e.id !== eventToDelete.id,
					);
				}
			}
		});

		setEvents(updatedEvents); // Update the state with the new events
		setSelectedEvents([]); // Clear the selected events
		saveCalendarData(updatedEvents);
	};

	const handleEventDrop = (dropInfo) => {
		const { event } = dropInfo;

		// Update the event in the `events` state
		const updatedEvents = events.map((e) =>
			e.id === event.id
				? {
						...e,
						start: event.start.toISOString(),
						end: event.end ? event.end.toISOString() : null,
					}
				: e,
		);

		setEvents(updatedEvents);
		saveCalendarData(updatedEvents);
	};

	const handleEventResize = (resizeInfo) => {
		const { event } = resizeInfo;

		// Update the event in the `events` state
		const updatedEvents = events.map((e) =>
			e.id === event.id
				? {
						...e,
						start: event.start.toISOString(),
						end: event.end ? event.end.toISOString() : null,
					}
				: e,
		);

		setEvents(updatedEvents);
		saveCalendarData(updatedEvents);
	};

	function isSameDay(date1, date2) {
		return (
			date1.getDate() === date2.getDate() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getFullYear() === date2.getFullYear()
		);
	}

	function wasLastRepeatingEventEntry(repeatingEvent) {
		// Use RRuleSet to handle exdate
		const rruleOptions = RRule.parseString(repeatingEvent.rrule);
		const rruleSet = new RRuleSet();

		// Add the RRule to the RRuleSet
		rruleSet.rrule(new RRule(rruleOptions));

		// Add all exdates to the RRuleSet
		if (repeatingEvent.exdate) {
			repeatingEvent.exdate.forEach((date) => {
				rruleSet.exdate(new Date(date));
			});
		}

		// Get all remaining occurrences
		const remainingOccurrences = rruleSet.all();

		return remainingOccurrences.length === 0;
	}

	return (
		<div className="Calendar" ref={calendarRef}>
			<FullCalendar
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					listPlugin,
					interactionPlugin,
					rrulePlugin,
				]}
				timeZone="local"
				eventTimeFormat={{
					hour: "2-digit",
					minute: "2-digit",
					hour12: false,
				}}
				initialView="dayGridMonth"
				headerToolbar={{
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
				}}
				selectable={true}
				editable={true}
				events={events}
				select={handleDateSelect}
				eventClick={handleEventClick}
				eventDidMount={(info) => {
					info.el.setAttribute("data-event-id", info.event.id);

					// Add the 'selected-event' class if the event is in the selectedEvents array
					if (selectedEvents.includes(info.event.id)) {
						info.el.classList.add("selected-event");
					}

					// Add a context menu listener for right-click events
					info.el.addEventListener("contextmenu", function (e) {
						e.preventDefault();
						handleEventRightClick(info, e);
					});
				}}
				height="100%"
				eventDrop={handleEventDrop}
				eventResize={handleEventResize}
				longPressDelay={100}
				eventLongPressDelay={100}
				selectLongPressDelay={100}
			/>
		</div>
	);
}

export default Calendar;

export const formatCalendarDate = (date, allDay = false) => {
	if (!date) return null;

	// Format for all-day events: YYYY-MM-DD
	if (allDay) {
		return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
			2,
			"0",
		)}-${String(date.getDate()).padStart(2, "0")}`;
	}

	// Format for timed events: YYYY-MM-DD HH:mm:ss
	return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
		2,
		"0",
	)}-${String(date.getDate()).padStart(2, "0")} ${String(
		date.getHours(),
	).padStart(2, "0")}:${String(date.getMinutes()).padStart(
		2,
		"0",
	)}:${String(date.getSeconds()).padStart(2, "0")}`;
};
